import SubHeader from "@/components/SubHeader";
import Forms from "./form/index";
import ClientInformationIcon from "@shared/assets/icons/tenant-client/client-information.svg";
import contactIcon from "@shared/assets/icons/tenant-client/contact.svg";
import addressIcon from "@shared/assets/icons/tenant-client/address.svg";
import financialsIcon from "@shared/assets/icons/tenant-client/financials.svg";
import configurationIcon from "@shared/assets/icons/tenant-client/configuration.svg";
import packagesAndChecksIcon from "@shared/assets/icons/tenant-client/packages-and-checks.svg";
import workAllocationIcon from "@shared/assets/icons/tenant-client/work-allocation.svg";
import groups from "@shared/assets/icons/tenant-client/groups.svg";
import users from "@shared/assets/icons/tenant-client/users.svg";
import { fetchClientInfo, initializeClient, updateClientInfo, onBoardClient, assignDashboard } from "./services";
import { checkPermission } from "@shared/utils/functions";

const DISABLED_TABS = ["packageandcheck", "work_allocation", "users", "groups", "rolesAndPermissions"];

export default {
    name: "create-client",
    components: {
        SubHeader,
    },
    created() {},
    async mounted() {
        await this.getClientDetails();
        this.checkActiveTab();
    },

    data() {
        return {
            createClientSections: [
                {
                    name: "client_information",
                    label: "Client Information",
                    values: {},
                    hasErrors: false,
                    disabled: false,
                    logo: ClientInformationIcon,
                    show: true,
                },
                {
                    name: "contact",
                    label: "Contact",
                    values: {},
                    hasErrors: false,
                    disabled: this.checkClientOnBoarding(),
                    logo: contactIcon,
                    show: true,

                },
                {
                    name: "address",
                    label: "Address",
                    values: {},
                    hasErrors: false,
                    disabled: this.checkClientOnBoarding(),
                    logo: addressIcon,
                    show: true,

                },
                {
                    name: "financial_details",
                    label: "Financial",
                    values: {},
                    hasErrors: false,
                    disabled: this.checkClientOnBoarding(),
                    logo: financialsIcon,
                    show: true,

                },
                {
                    name: "configuration",
                    label: "Configuration",
                    values: {},
                    hasErrors: false,
                    disabled: this.checkClientOnBoarding(),
                    logo: configurationIcon,
                    show: true,

                },
                {
                    name: "packageandcheck",
                    label: "Package and Checks",
                    logo: packagesAndChecksIcon,
                    disabled: this.checkClientOnBoarding(),
                    show: true,

                },
                {
                    name: "work_allocation",
                    label: "Work Allocation",
                    logo: workAllocationIcon,
                    disabled: this.checkClientOnBoarding(),
                    show: true,

                },
                {
                    name: "groups",
                    label: "Groups",
                    logo: groups,
                    disabled: this.checkClientOnBoarding(),
                    show: true,

                },
                {
                    name: "users",
                    label: "Users",
                    logo: users,
                    disabled: this.checkClientOnBoarding(),
                    show: true,

                },
                {
                    name: "rolesAndPermissions",
                    label: "Roles And Permissions",
                    logo: users,
                    disabled: this.checkClientOnBoarding(),
                    show: true,

                },
            ],
            FormComponents: Forms,
            selectedSection: "",
            customBreadCrumbs: [],
            clientId: null,
            clientDetails: null,
            loadingStates:{
                client: false,
                formSubmit: false
            }
        };
    },
    computed: {
        activeForm: {
            get() {
                return this.createClientSections.find((el) => el.name === this.selectedSection);
            },
            set(val) {
                console.log(val, ">>>val");
            },
        },
        getCreateClientSection:{
            get(){
                return this.createClientSections.map(el => {
                    return {
                        ...el,
                        ...(DISABLED_TABS.includes(el.name) ? { disabled: !this.isClientOnboarded } : {})
                    }

                })
            },
            set(val){
                this.createClientSections = val
            }
        },
        isClientOnboarded(){
            return this.clientDetails?.onboarded || false
        }
    },
    methods: {
        checkPermission,
        checkClientOnBoarding() {
            return this.$route.name === "create-client";
        },
        async getClientDetails() {
            this.loadingStates.client = true
            this.clientId = this.$route.params.clientId;
            this.clientId && (this.clientDetails = await fetchClientInfo(this.clientId))
            this.loadingStates.client = false
        },
        checkActiveTab() {
            let active_tab = this.$route.params.tab;
            let query_data = this.$route.query;
            if(this.checkClientOnBoarding()){
                active_tab = 'client_information'
            }
            active_tab ? this.$router.push({ params: { tab: active_tab }, query: query_data }) : this.$router.push({ params: { tab: "client_information" }, query: query_data });
            this.selectedSection = active_tab ? active_tab : "client_information";
            this.setBredCrumbs();
        },
        sectionClick({ name, disabled }) {
            if (this.selectedSection !== name && !disabled) {
                this.selectedSection = name;
                this.$router.push({ params: { tab: name } });
                this.customBreadCrumbs = [{ name: this.selectedSection }];
                this.setBredCrumbs();
            }
        },
        async handleSubmit({data, onBoard}) {
            this.loadingStates.formSubmit = true
            if(this.checkClientOnBoarding()){
                try{
                    let response =  await initializeClient(data);
                    await this.$router.push({
                        name: "client-details",
                        params: { 
                            clientId: response.id,
                            tab: "client_information",
                        }, 
                    })
                    this.$toast.success(response.message || 'Saved!');
                    this.clientId = response.id
                    await this.getClientDetails();
                    this.checkActiveTab()
                    this.getCreateClientSection = this.getCreateClientSection.map(el => ({...el, disabled:false}))
                    this.clientDetails = response
                    this.handleNext(data)
                }catch(error){
                    this.$toast.error(error?.response?.data?.detail || 'failed to initialize client')
                }
            }else{
                try{
                    let response = await updateClientInfo(this.clientId, data)
                    this.clientDetails = {...this.clientDetails, ...data}
                    if(onBoard && this.clientDetails.name){
                        let error = await this.handleOnBoardClient()
                        if(error){
                            this.loadingStates.formSubmit = false
                            return this.$toast.error(error?.response?.data?.detail || 'Failed to Onboarded Client')
                        }
                        else {
                            let payload = []
                            this.clientDetails.dashboards.forEach((res)=>{
                                payload.push({"dashboard_id":res.id,"client_id":this.clientId})
                            })
                            await assignDashboard(payload)
                        } 
                    }
                    this.$toast.success(response.message || 'updated')
                    !onBoard && this.handleNext(data)
                   
                }
                catch(error){
                    this.loadingStates.formSubmit = false
                    if(error && error?.response?.data?.detail) {
                        if(error?.response?.data?.detail[0]?.type) {
                            let errorType = error?.response?.data?.detail[0]?.type?.split('.');
                            if(errorType?.includes('max_length')) {
                                this.loadingStates.formSubmit = false
                                return this.$toast.error('Tax number length can not be greater than 20!')
                            }
                            if(errorType?.includes('not_lt') && error?.response?.data?.detail[0]?.loc?.includes('max_users')) {
                                this.loadingStates.formSubmit = false
                                return this.$toast.error('Max users count should be less than 2147483647!')
                            }
                            if(errorType?.includes('not_lt') && error?.response?.data?.detail[0]?.loc?.includes('max_candidate')) {
                                this.loadingStates.formSubmit = false
                                return this.$toast.error('Max candidate count should be less than 2147483647!')
                            }
                        }
                    }
                    this.$toast.error(error?.response?.data?.detail || 'failed to update')
                }
                
            }
            // let currentIndex = this.createClientSections.findIndex((el) => el.name === this.selectedSection);
            // if (currentIndex < this.createClientSections.length - 1) {
            //     let nextSec = this.createClientSections[currentIndex + 1];
            //     this.selectedSection = nextSec.name;
            //     this.$router.push({ params: { tab: nextSec.name } });
            // }
            this.loadingStates.formSubmit = false

        },
        handleNext(){
            let currentIndex = this.getCreateClientSection.findIndex((el) => el.name === this.selectedSection);
            if (currentIndex < this.getCreateClientSection.length - 1) {
                let nextSec = this.getCreateClientSection[currentIndex + 1];
                this.selectedSection = nextSec.name;
                this.$router.push({ params: { tab: nextSec.name } });
            }
        },
        async handleOnBoardClient(){
            try{
                let {data, error} = await onBoardClient(this.clientId)
                if(error) {
                    this.$toast.error(error.response.data.detail)
                    return error;
                }
                data && this.$toast.success(data.message || 'Client Onboarded')
                this.clientDetails = {...this.clientDetails, onboarded:data ? true :false}
                data && this.handleNext(data)
                // error && this.$toast.error(error.response.data.detail || 'Client Onboarded')

            }
            catch(error){
                this.$toast.error(error.response.data.detail || 'Failed to onboard client')
            }
        },
        handleBackSection() {
            let currentIndex = this.getCreateClientSection.findIndex((el) => el.name === this.selectedSection);
            if (currentIndex > 0) {
                let prevSec = this.getCreateClientSection[currentIndex - 1];
                this.selectedSection = prevSec.name;
                this.$router.push({ params: { tab: prevSec.name } });
            }
        },
        // isSectionDisable() {
        //     return !this.createClientSections.find((el) => el.name === this.selectedSection);
        // },
        setBredCrumbs(crumb) {
            this.customBreadCrumbs = [];
            let selectedKey = this.getCreateClientSection.find((el) => el.name === this.selectedSection);
            this.customBreadCrumbs = [{ name: selectedKey.label.split("_").join(" ") }];
            if (crumb) {
                this.customBreadCrumbs.push({ name: crumb });
            }
        },
    },
};
