<template>
    <div class="flex flex-col max-h-full gap-4 flex-1">
        <h2 v-if="showTitle" class="font-semibold text-gray-600 text-lg capitalize">{{ title }}</h2>
        <div v-if="showSearch">
            <div class="search_container flex items-center gap-4 text-gray-500">
                <div v-if="showCheckBox && showSelectAll" title="Select all">
                    <svg @click="handleSelectAll" class="h-7 w-7 cursor-pointer" :class="isCheckboxDisabled && 'cursor-not-allowed'" viewBox="0 0 16 16" :fill="isAllSelected" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="1">
                            <g clip-path="url(#clip0_4665_6289)">
                                <path
                                    d="M9.04766 12.0447H3.01553C1.35291 12.0447 0 10.6941 0 9.0338V3.01091C0 1.35084 1.35291 0 3.01553 0H9.04739C10.7103 0 12.0629 1.35084 12.0629 3.01091V9.03354C12.0629 10.6939 10.71 12.0444 9.04739 12.0444L9.04766 12.0447ZM3.01553 1.10734C1.96422 1.10734 1.10904 1.96121 1.10904 3.01091V9.03354C1.10904 10.0832 1.96422 10.9371 3.01553 10.9371H9.04739C10.0987 10.9371 10.9539 10.0832 10.9539 9.03354V3.01091C10.9539 1.96121 10.0987 1.10734 9.04739 1.10734H3.01553Z"
                                    fill="current-color"
                                />
                                <path d="M9.49067 13.9157H3.2214C2.91521 13.9157 2.66675 13.6679 2.66675 13.3619C2.66675 13.0559 2.91494 12.8081 3.2214 12.8081H9.4904C11.3284 12.8081 12.8235 11.3153 12.8235 9.48019V3.2208C12.8235 2.91507 13.0716 2.66699 13.3781 2.66699C13.6846 2.66699 13.9328 2.9148 13.9328 3.2208V9.48019C13.9328 11.926 11.9399 13.9155 9.49067 13.9155V13.9157Z" fill="current-color" />
                                <path d="M10.129 16.028H5.88791C5.58171 16.028 5.33325 15.7801 5.33325 15.4742C5.33325 15.1682 5.58144 14.9203 5.88791 14.9203H10.1287C12.7793 14.9203 14.9356 12.7674 14.9356 10.1209V5.88681C14.9356 5.58109 15.1838 5.33301 15.4902 5.33301C15.7967 5.33301 16.0449 5.58082 16.0449 5.88681V10.1212C16.0449 13.3783 13.3911 16.028 10.129 16.028V16.028Z" fill="current-color" />
                                <path
                                    d="M9.28787 3.71789C9.07124 3.50159 8.72026 3.50159 8.50364 3.71789L4.91673 7.2993L3.61345 5.99802C3.39682 5.78172 3.04585 5.78172 2.82922 5.99802C2.61259 6.21432 2.61259 6.56475 2.82922 6.78105L4.45568 8.40502C4.46728 8.41661 4.47969 8.42738 4.49183 8.43789C4.50235 8.45028 4.51288 8.4624 4.52475 8.47398C4.63292 8.58199 4.77483 8.63614 4.91673 8.63614C5.05863 8.63614 5.20053 8.58199 5.30871 8.47398L9.28787 4.50092C9.5045 4.28462 9.5045 3.93418 9.28787 3.71789Z"
                                    fill="current-color"
                                />
                            </g>
                        </g>
                        <defs>
                            <clipPath id="clip0_4665_6289">
                                <rect width="16" height="16" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>

                <Input class="flex-1" @input="(value) => $emit('handleFilterResult', value)" placeholder="Search" icon="search" />
            </div>
        </div>
        <div v-if="getLists && getLists.length > 0" class="overflow-y-auto scroll-bar rounded-lg flex flex-col h-full flex-1" :class="{ 'divide-y border': bordered }">
            <slot name="listheader"> </slot>
            <div class="flex-1 overflow-y-auto scroll-bar">
                <div v-for="item in getLists" class="flex cursor-pointer gap-2 items-center p-2 list_item bg-white" :key="item.id">
                    <slot name="item" :item="item">
                        <div class="flex gap-3" v-if="showCheckBox">
                            <input v-model="item[selectKey]" type="checkbox" :disabled="isCheckboxDisabled" @input.stop="(value) => rowClick(item, value)" :id="item.id" class="checkbox cursor-pointer checkbox-primary border-box-stroke checkbox-sm flex" />
                            <label :for="item.id" class="cursor-pointer">{{ item[valueKey] }}</label>
                        </div>
                        <div v-else class="flex gap-3 w-full" @click.stop="rowClick(item)">
                            {{ item[valueKey] }}
                        </div>
                    </slot>
                </div>
            </div>
        </div>
        <div v-else class="flex py-10 justify-center h-full border rounded">
            <Empty :showEmptyIcon="true" text="No result found" />
        </div>
    </div>
</template>
<script>
import Input from "../../input";
import Empty from "../../empty";

export default {
    name: "list-view",
    components: {
        Input,
        Empty,
    },
    props: {
        listItems: {
            type: Array,
            default: () => [],
        },
        showSearch: {
            type: Boolean,
            default: false,
        },
        showEmptyIcon: {
            type: Boolean,
            default: true,
        },
        valueKey: {
            type: String,
            default: "name",
        },
        bordered: {
            type: Boolean,
            default: true,
        },
        showCheckBox: {
            type: Boolean,
            default: true,
        },
        showSelectAll: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "Title",
        },
        disableCheckbox: {
            type: Boolean,
            default: false,
        },
        selectKey: {
            type: String,
            default: "checked",
        },
        activeKey: {
            type: String,
            default: "",
        },
        showTitle: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            selectAll: false,
        };
    },
    beforeUpdate(){
        this.listItems.sort(
            (a,b) => {
                if(a?.checked && !b?.checked){
                    return -1
                }
                if(!a?.checked && b?.checked){
                    return 1
                }
            }
        )
    },
    mounted() {},
    created(){
        this.listItems.sort(
            (a,b)=>{
                if(a?.checked && !b?.checked){
                    return -1
                }
                if(!a?.checked && b?.checked){
                    return 1
                }
            }
        )
    },
    computed: {
        getLists: {
            get() {
                return this.listItems;
            },
        },
        checkAllSelected: {
            get() {                
                return this.listItems.length === this.listItems.filter((el) => 
                    this.activeKey ? 
                        el[this.selectKey] || !el[this.activeKey] : 
                        el[this.selectKey] 
                )?.length;
            },
            set(val) {
                return (this.selectAll = val);
            },
        },
        isAllSelected() {
            return this.checkAllSelected && this.getLists && this.getLists.length > 0 ? "#0D69D5" : "gray";
        },
        isCheckboxDisabled() {
            return this.disableCheckbox;
        },
    },
    methods: {
        rowClick(item, value) {
            let payload = {
                row: item,
            };
            if (value) {
                payload = { ...payload, value: value.target.checked };
            }
            this.$emit("rowClick", payload);
        },
        handleSelectAll() {
            if (this.isCheckboxDisabled) return;
            this.checkAllSelected = !this.checkAllSelected;
            this.$emit("handleSelect", this.selectAll);
        },

    },
};
</script>
<style scoped lang="scss">
.list_item:nth-child(odd) {
    @apply bg-gray-100;
}
.list_item:hover {
    @apply bg-blue-100;
}
</style>
