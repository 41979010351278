<template>
  <div
    class="flex flex-col gap-y-6 overflow-y-auto w-full"
    v-allow:auth="'role.read'"
  >
    <div class="flex flex-col gap-y-6 overflow-y-auto scroll-bar flex-1">
      <div
        v-if="loadingStates.role"
        class="min-h-16 flex justify-center items-center"
      >
        <Loader />
      </div>
      <PermissionInfoCard
        v-else
        @updateRole="updateRole"
        :roleInfo="roleInfo"
        :loadingStates="loadingStates"
        @deleteRole="deleteRole"
      />
      <div
        class="flex gap-x-6 flex-1 w-full flex-col xl:flex-row gap-y-6 overflow-y-auto scroll-bar"
      >
        <div
          class="bg-card-bg rounded-lg p-4 flex flex-col gap-y-3 xl:w-6/12 w-full h-full overflow-y-auto overflow-x-hidden"
          :style="{
            minHeight: '300px',
            minWidth: '300px',
          }"
        >
          <h1 class="text-gray-600 font-semibold">Permissions</h1>
          <div
            v-if="loadingStates.permission"
            class="flex justify-center items-center"
          >
            <Loader />
          </div>
          <div
            v-else
            class="flex flex-col gap-y-3 overflow-y-auto justify-between flex-1"
          >
            <permissionsCard
              :disabled="!checkPermission('role.edit')"
              @rowClick="permissionsRowCLick($event)"
              @viewPermissionsInfo="viewPermissionsInfo($event)"
              @selectAllPer="selectAllPer($event)"
              :Permissions="getPermissionsList"
            />
            <div
              v-if="permissions && permissions.length"
              class="flex flex-1 justify-between items-center"
            >
              <Button
                @click="getPermissions"
                :disabled="
                  loadingStates.permissionsEdit || !checkPermission('role.edit')
                "
                class="disabled:opacity-50"
                text="Cancel"
                type="ghost"
              />
              <Button
                @click="updatePermissions"
                :disabled="
                  loadingStates.permissionsEdit || !checkPermission('role.edit')
                "
                class="disabled:opacity-50"
                text="Save"
                type="primary"
              />
            </div>
            <div v-else>
              <Empty text="Result not found" />
            </div>
          </div>
        </div>
        <div class="flex-1 flex gap-x-6">
          <div class="overflow-y-auto flex bg-card-bg p-4 rounded-md flex-1">
            <div
              v-if="loadingStates && loadingStates.users"
              class="flex flex-1 justify-center items-center"
            >
              <Loader />
            </div>

            <div
              v-else
              class="flex flex-col gap-y-3 overflow-y-auto overflow-x-hidden justify-between flex-1"
            >
              <ListView
                selectKey="selected"
                :disableCheckbox="!disableUsers"
                @handleSelect="selectAll($event, 'users')"
                title="Users"
                :showSelectAll="true"
                :listItems="filterdUsers"
                :showSearch="true"
                class="h-full overflow-y-auto"
                @handleFilterResult="handleFilterResult($event, 'users')"
              />
              <div
                v-if="filterdUsers && filterdUsers.length"
                class="flex justify-between items-center"
              >
                <Button
                  @click="fetchUsersList"
                  :disabled="loadingStates.userEdit || !disableUsers"
                  class="disabled:opacity-50"
                  text="Cancel"
                  type="ghost"
                />
                <Button
                  @click="updateUsers"
                  :disabled="loadingStates.userEdit || !disableUsers"
                  class="disabled:opacity-50"
                  text="Save"
                  type="primary"
                />
              </div>
            </div>
          </div>
          <div class="overflow-y-auto flex bg-card-bg p-4 rounded-md flex-1">
            <div
              v-if="loadingStates && loadingStates.groups"
              class="flex flex-1 justify-center items-center"
            >
              <Loader />
            </div>

            <div
              v-else
              class="flex flex-col gap-y-3 overflow-y-auto overflow-x-hidden justify-between flex-1"
            >
              <ListView
                selectKey="selected"
                :disableCheckbox="!disableGroups"
                @handleSelect="selectAll($event, 'groups')"
                title="groups"
                :showSelectAll="true"
                :listItems="filterdGroups"
                :showSearch="true"
                class="h-full overflow-y-auto"
                @handleFilterResult="handleFilterResult($event, 'groups')"
              />
              <div
                v-if="filterdGroups && filterdGroups.length"
                class="flex justify-between items-center"
              >
                <Button
                  @click="fetchGroupsList"
                  :disabled="loadingStates.groupEdit || !disableGroups"
                  class="disabled:opacity-50"
                  text="Cancel"
                  type="ghost"
                />
                <Button
                  @click="updateGroups"
                  :disabled="loadingStates.groupEdit || !disableGroups"
                  class="disabled:opacity-50"
                  text="Save"
                  type="primary"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Drawer ref="drawer">
      <div>
        <div
          class="bg-gray-100 text-base-content px-6 py-2 text-lg font-semibold flex items-center gap-3"
        >
          <button
            @click="closeDrawer"
            class="h-6 w-6 flex justify-center cursor-pointer rounded-full items-center hover:bg-gray-300 text-gray-600 font-semibold"
          >
            <font-awesome-icon icon="times" />
          </button>
          Permission Information
          <span
            v-if="currentPermission && currentPermission.name"
            class="text-primary"
          >
            " {{ currentPermission && currentPermission.name }} "
          </span>
        </div>
        <div class="m-6">
          <h2 class="text-gray-600">
            {{ currentPermission && currentPermission.description }}
          </h2>
        </div>
      </div>
    </Drawer>
    <ModalConfirm
      title="Are you sure?"
      message="Please confirm you're about to delete the field."
      ref="confirm-popup"
    >
    </ModalConfirm>
  </div>
</template>

<script>
import Empty from "@shared/empty";
import { PermissionInfoCard } from "./components";
import permissionsCard from "@shared/components/roles-and-permissions/permissionsCard";
import ListView from "@shared/components/lists";
import axios from "@/axios";
import Loader from "@shared/loader";
import Button from "@shared/components/button";
const ModalConfirm = () => import("@shared/modal-confirm");
const Drawer = () => import("@shared/components/drawer");
import { checkPermission } from "@shared/utils/functions";
import { adminList } from "../utils/constants";
import { getApiUrlPrefix } from "./utils"

export default {
  name: "role-details-container",
  components: {
    PermissionInfoCard,
    permissionsCard,
    ListView,
    Loader,
    Button,
    ModalConfirm,
    Empty,
    Drawer,
  },
  props: {
    roleId: {
      type: String,
      required: true,
    },
    // logic with API URL determination may be changed:
    adminPanel: {
      type: String,
      default: adminList.TENANT,
    },
    clientId: {
      type: String,
      default: "",
    },
  },
  title: "Roles and Permissions",
  data: () => ({
    roleInfo: null,
    permissions: [],
    loadingStates: {
      groups: false,
      users: false,
      role: false,
      permission: false,
      permissionsEdit: false,
      deleteRole: false,
      groupEdit: false,
      userEdit: false,
      updateRole: false,
    },
    serchValues: {
      groups: "",
      users: "",
    },
    users: [],
    groups: [],
    tenant_id: null,
    currentPermission: null,
  }),

  computed: {
    filterdGroups() {
      return this.groups?.filter((el) =>
        el.name?.toLowerCase()?.includes(this.serchValues.groups.toLowerCase())
      );
    },
    filterdUsers() {
      return this.users?.filter((el) =>
        el.name?.toLowerCase()?.includes(this.serchValues.users.toLowerCase())
      );
    },
    getPermissionsList() {
      return this.permissions;
    },
    disableUsers() {
      return (
        this.checkPermission("role.edit") && this.checkPermission("users.read")
      );
    },
    disableGroups() {
      return (
        this.checkPermission("role.edit") && this.checkPermission("groups.read")
      );
    },
  },
  async mounted() {
    this.tenant_id = this.$store.getters.getTenantId;
    await this.getRoleDetails();
    await this.getPermissions();
    await this.fetchUsersList();
    await this.fetchGroupsList();
  },
  methods: {
    checkPermission,
    getURL(url) {
      const urlPayload = {
        client_id: this.clientId,
      }
      const urlPrefix = getApiUrlPrefix(this.adminPanel, urlPayload);

      return urlPrefix + url;
    },
    async fetchUsersList() {
      this.loadingStates.users = true;
      let url = this.getURL(`${this.roleId}/users`);
      let items = null;
      try {
        let { data } = await axios.get(url);
        this.users = data;
        this.serchValues["users"] = "";
      } catch (error) {
        console.log(error, "<<<<error while fetching users");
      }
      this.loadingStates.users = false;
      return items;
    },
    async updateRole() {
      this.loadingStates.updateRole = true;
      let url = this.getURL(this.roleId);
      try {
        let { name, description } = this.roleInfo;
        let { data } = await axios.put(url, { name, description });
        this.$toast.success(data.message || "Role updated!");
      } catch (error) {
        this.$toast.error(error.response.data.detail || "Failed to update!");
      }
      this.loadingStates.updateRole = false;
    },
    async getRoleDetails() {
      this.loadingStates.role = true;
      let url = this.getURL(this.roleId);
      try {
        let { data } = await axios.get(url);
        this.roleInfo = data;
      } catch (error) {
        console.log(error, "<<<<error while fetching role details");
      }
      this.loadingStates.role = false;
    },
    async fetchGroupsList() {
      this.loadingStates.groups = true;
      let url = this.getURL(`${this.roleId}/groups`);
      let items = null;
      try {
        let { data } = await axios.get(url);
        this.groups = data;
        this.serchValues["groups"] = "";
      } catch (error) {
        console.log(error, "<<<<error while fetching groups");
      }
      this.loadingStates.groups = false;
      return items;
    },
    handleFilterResult(event, type) {
      this.serchValues[type] = event;
    },
    async deleteRole() {
      const promise = await this.$refs["confirm-popup"].show({
        title: "Are you sure?",
        message:
          "This Role  will be deleted permanently. Please confirm to continue deleting this role.",
        buttonText: "Confirm",
      });
      if (promise) {
        this.loadingStates.deleteRole = true;
        try {
        const url = this.getURL(this.roleId);
          let { data } = await axios.delete(url);
          this.$router.push({
            name: "roles-and-permissions-list",
          });
          this.$toast.success(data.message || "Role deleted!");
        } catch (error) {
          console.log(error, ">>>>error");
          this.$toast.error(
            error.response.data.detail || "Failed to delete role!"
          );
        }
        this.loadingStates.deleteRole = false;
      }
      this.$refs["confirm-popup"].close();
    },
    async getPermissions() {
      this.loadingStates.permission = true;
      try {
        const url = this.getURL(`${this.roleId}/permissions`);
        let { data } = await axios.get(url);
        this.permissions = data;
      } catch (error) {
        console.log(error, ">>>>error");
      }
      this.loadingStates.permission = false;
    },
    async updatePermissions() {
      this.loadingStates.permissionsEdit = true;
      try {
        const url = this.getURL(`${this.roleId}/permissions`);
        let { data } = await axios.post(
          url,
          this.permissions
        );
        // this.rows.push({ ...roles_and_permission, id: data.id, permissions_count: 0, users_count: 0, groups_count: 0,  });
        this.$toast.success(data.message || "Role updated!");
      } catch (error) {
        console.log(error, ">>>>error");
        this.$toast.error(
          error.response.data.detail || "Failed to update role!"
        );
      }
      this.loadingStates.permissionsEdit = false;
    },
    async updateUsers() {
      this.loadingStates.userEdit = true;
      try {
        const url = this.getURL(`${this.roleId}/users`);
        let { data } = await axios.post(
          url,
          this.users
        );
        // this.rows.push({ ...roles_and_permission, id: data.id, permissions_count: 0, users_count: 0, groups_count: 0,  });
        this.$toast.success(data.message || "Role updated!");
      } catch (error) {
        console.log(error, ">>>>error");
        this.$toast.error(
          error.response.data.detail || "Failed to update role!"
        );
      }
      this.loadingStates.userEdit = false;
    },
    async updateGroups() {
      this.loadingStates.groupEdit = true;
      try {
        const url = this.getURL(`${this.roleId}/groups`);
        let { data } = await axios.post(
          url,
          this.groups
        );
        // this.rows.push({ ...roles_and_permission, id: data.id, permissions_count: 0, users_count: 0, groups_count: 0,  });
        this.$toast.success(data.message || "Role updated!");
      } catch (error) {
        console.log(error, ">>>>error");
        this.$toast.error(
          error.response.data.detail || "Failed to update role!"
        );
      }
      this.loadingStates.groupEdit = false;
    },
    selectAllPer({ input, index, all, per }) {
      input = input.target.checked;
      if (all) {
        this.permissions = this.permissions.map((el, id) => {
          if (id === index) {
            return {
              ...el,
              permissions: el.permissions.map((per) => ({
                ...per,
                selected: input,
              })),
            };
          } else {
            return el;
          }
        });
        console.log(this.permissions);
      } else {
        this.permissions = this.permissions.map((el) => {
          let permissions_checked_count = el.permissions.filter(
            (el) => el.selected
          ).length;
          if (permissions_checked_count === el.permissions.length) {
            return { ...el, selected: true };
          } else {
            return { ...el, selected: false };
          }
        });
      }
      this.permissionsRowCLick(per);
    },
    selectAll(event, type) {
      this[type] = this[type].map((el) => ({ ...el, selected: event }));
    },
    openDrawer() {
      this.$refs.drawer.open();
    },
    closeDrawer() {
      this.$refs.drawer.close();
    },
    permissionsRowCLick(event) {
      this.currentPermission = null;
      if (event && event.description && event.selected) {
        this.currentPermission = event;
        this.openDrawer();
      }
    },
    viewPermissionsInfo(event) {
      this.currentPermission = null;
      if (event && event.description) {
        this.currentPermission = event;
        this.openDrawer();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>